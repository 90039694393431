import { createGlobalStyle } from 'styled-components';

import 'antd/dist/antd.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import '~/assets/css/buttons.css';
import '~/assets/css/antd.css';
import '~/assets/css/tabs.css';
import '~/assets/css/common.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;

    &:focus{
      outline: 0;
    }
  }

  html, body, #root {
    user-select: none;
    height: 100%;
  }

  body{
    -webkit-font-smoothing: antialiased !important;
  }

  body, input, button {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  form {
    hr {
      border: 1px solid var(--card-task-input-border);
    }
  }

  .clickable {
    cursor: pointer;
  }

  .container-fluid {
    padding: 20px 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .text-danger {
    color: #dc3545 !important;
  }

  .text-success {
    color: #28a745 !important;
  }
  
  .active {
    color: #0a0;

    svg {
      fill: #0a0;
    }
  }

  .ql-editor {
    min-height: 300px;
  }

  .v-separator {
    width: 0.2rem;
    background-color: transparent;
    border-left: 0.1rem solid #ccc;
    margin: 0 0.8rem;
  }
`;
